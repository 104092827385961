import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { Navigation } from 'swiper/modules';


import Footer from "./Footer";
import { useState, useEffect } from "react";

const Projects = () => {
  const [projectData, setprojectData] = useState([]);
  const [activeSection, setActiveSection] = useState("Completed");
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleDescriptionLength = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Collapse the description
    } else {
      setExpandedIndex(index); // Expand the description
    }
  };

  // Function to fetch project data
  const get_projectData = () => {
    axios
      .post(getBaseUrl() + "user_api/projectList", {
        status: activeSection,
      })
      .then((res) => {
        console.log("API Response get_projectData:", res.data);
        if (res.data.status) setprojectData(res.data.results);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  };

  // Handle button click and update active section
  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  // Fetch data when activeSection changes
  useEffect(() => {
    get_projectData();
  }, [activeSection]);

  // Initial scroll to top and fetch data
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <section className="About_section_main_all">
        <div className="container-xl">
          <div className="row About_section_ttete">
            <div className="col-md-6 About_section_text">
              <h1>A Glimpse into Our Work.</h1>
              <p>
                We've successfully delivered projects that showcase our
                capabilities. Our approach is centered around understanding your
                unique needs and tailoring our solutions accordingly.
              </p>
            </div>
            <div className="col-md-6 About_section_images">
              <img
                src="/Images/Images provided by Bhaskar ji/projects_images.png"
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Project Navigation Buttons */}
      <section className="project_section_main">
        <div className="container-xl">
          <div className="row">
            <div className="col-sm-4">
              <button
                onClick={() => handleButtonClick("Completed")}
                className={activeSection === "Completed" ? "active" : ""}
              >
                Completed
              </button>
            </div>
            <div className="col-sm-4">
              <button
                onClick={() => handleButtonClick("In Progress")}
                className={activeSection === "In Progress" ? "active" : ""}
              >
                In Progress
              </button>
            </div>
            <div className="col-sm-4">
              <button
                onClick={() => handleButtonClick("Upcoming")}
                className={activeSection === "Upcoming" ? "active" : ""}
              >
                Upcoming
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Project Slider */}
      <section className="projects_main_section_slider">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <Swiper
                spaceBetween={30}
                slidesPerView={2}
                speed={1500}
              
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  100: { slidesPerView: 1, spaceBetween: 20 },
                  620: { slidesPerView: 1, spaceBetween: 20 },
                  1024: { slidesPerView: 2, spaceBetween: 20 },
                }}
                modules={[Pagination,Autoplay]}
                className="mySwiper"
                class="swiper-slide"
                navigation
              >
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper"></Swiper>
         {projectData.map((eachData, index) => (
          
        <SwiperSlide key={index}>
          <div className="Our_porjectes_section">
            
            <div className="Our_projects_items">
              <img src={eachData.image} alt="project" />

              <h4>{eachData.name}</h4>

              <div>
              <p>
            {expandedIndex === index ? (
              <span dangerouslySetInnerHTML={{ __html: eachData.description }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: eachData.description.slice(0, 70) +'... ' }} />
            )}
            {expandedIndex !== index && (
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => toggleDescriptionLength(index)} 
              >
              Read More
              </span>
            )}
            {expandedIndex === index && (
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => toggleDescriptionLength(index)} 
              >
                Read Less
              </span>
            )}
          </p>
              
               
              </div>
             

              {/* Area Images */}
         
             <div className="Area_images">
                <img
                  src="/Images/Images provided by Bhaskar ji/Area_images.svg"
                  alt="area"
                />
                <p>PLOT AREA</p>
              </div>
             
        

              {/* Another Heading */}
              <h5>{eachData.name}</h5>
            </div>
            </div>
        </SwiperSlide>
      ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Projects;
